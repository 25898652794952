import { useState, useEffect } from "react";
import "./setup.css";
import { Navbar } from "../../components/Navbar/navbar";
import axios, * as others from "axios";
import { useNavigate } from "react-router-dom";

export const Setup = (props) => {
  const navigate = useNavigate();
  const [subjects, setSubjects] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [videoId, setVideoId] = useState();

  const [categoryData, setCategoryData] = useState([""]);
  const [batchData, setBatchData] = useState([""]);

  useEffect(() => {
    axios
      .get(
        "https://sheets.googleapis.com/v4/spreadsheets/1P0STn63YCMXpFlggmpSqDVj9KHm47TKNimRIzoKZL_Y/values/YoutubePolls!$J$2?key=AIzaSyAaq4k_2Bb_E9xTaJdEeQ9RmEvEu_1AxHQ"
      )
      .then((res) => {
        const data = JSON.parse(res.data.values[0][0]);

        let categories = [];

        // Finding all unique categories
        data.data.forEach((e) => {
          if (categories.indexOf(e.category) == -1) categories.push(e.category);
        });

        setCategoryData(categories);
        setBatchData(data.data);
        console.log(categoryData);
        console.log(batchData);
      });
  }, [setBatchData, setCategoryData]);

  const getSchedule = (e) => {
    axios
      .post("https://polls.betterpw.live/api/get-live-streams", {
        channel_id: e.target.value,
      })
      .then((res) => {
        setSchedules(res.data.videos);
        console.log(res.data)
      });
  };

  const getVideo = (e) =>{
    console.log(e.target.value)
    setVideoId(e.target.value)
  }

  const start = (e) => {
    axios
      .post("https://polls.betterpw.live/api/start-doubts", {
        video_id: videoId,
      })
      
    navigate(`/doubts?id=${videoId}`) 
  }


  return (
    <div className="main">
      <div className="navbarDiv">
        <Navbar />
      </div>
      <div className="setupWrapper">
        <div className="setup">
          <div className="dropdownContainer">
            <div className="detailsDiv">
              <h1 className="dropdownLabel">Select Channel</h1>
              <select className="dropdowns" onChange={getSchedule}>
                <option value="NA"></option>
                {batchData.map((batch) => {
                  return <option value={batch.id}>{batch.name}</option>;
                })}
              </select>
            </div>
            <div className="detailsDiv">
              <h1 className="dropdownLabel">Select Video</h1>
              <select className="dropdowns" onChange={getVideo}>
                <option value="NA"></option>
                {schedules.map((schedule) => {
                  return (
                    <option value={`${schedule.id}`}>{schedule.title}</option>
                  );
                })}
              </select>
            </div>

            <div className="start">
              <button className="purpleButton" onClick={start}>Start</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
