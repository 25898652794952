import "./doubts.css";
import { useState,useEffect,useCallback,useRef } from "react";
import { Navbar } from "../../components/Navbar/navbar";
import axios from "axios";
import ScrollToBottom from "react-scroll-to-bottom";

export const Doubts = () => {
  const [toggleAll, setToggleAll] = useState("toggleAll");
  const [togglePopular, setTogglePopular] = useState("toggleButtons");
  const [allDoubts, setAllDoubts] = useState("containerWrapper");
  const [popularDoubts, setPopularDoubts] = useState("invisible");
  const [videoId,setVideoId] = useState('')
  const [doubtsArr,setDoubtsArr] = useState([{doubt: "", name:"", id:"a"}]);
  const [popularDoubtsArr,setPopularDoubtsArr] = useState([{doubt: "", name:"", id:"a"}])

  
  useEffect(() => {
    const video = (window.location.href.split("/")[3])
    setVideoId(video.slice(10));
  },[setVideoId]);


  const useIntervalAsync = (fn: () => Promise<unknown>, ms: number) => {
    const timeout = useRef<number>();
    const run = useCallback(async () => {
      await fn();
      timeout.current = window.setTimeout(run, ms);
    }, [fn, ms]);
    useEffect(() => {
      run();
      return () => {
        window.clearTimeout(timeout.current);
      };
    }, [run]);
  };
  
  
  const updateState = useCallback(async () => {
    await axios.get(`https://polls.betterpw.live/api/raw-doubts-data/${(window.location.href.split("/")[3]).slice(10)}`)
    .then((res) => {
    setDoubtsArr(res.data.doubts)
    setPopularDoubtsArr(res.data.popular)

    })
  }, []);
  useIntervalAsync(updateState, 8000);

  function handleToggleAll() {
    setToggleAll("toggleAll");
    setTogglePopular("toggleButtons");
    setPopularDoubts("invisible");
    setAllDoubts("containerWrapper");
  }

  function handleTogglePopular() {
    setTogglePopular("togglePopular");
    setToggleAll("toggleButtons");
    setPopularDoubts("containerWrapper");
    setAllDoubts("invisible");
  }

  function changePopular(e){
    e.popular = !e.popular
    console.log(doubts)
  }

  const setPopular = (e) => {
    console.log(e)
    axios
      .post("https://polls.betterpw.live/api/set-popular", {
        video_id: videoId,
        doubt_id: e
      })
  }

  const unsetPopular = (e) => {
    console.log(e)
    axios
      .post("https://polls.betterpw.live/api/unset-popular", {
        video_id: videoId,
        doubt_id: e
      })
  }

  return (
    <div className="main">
      <div className="navbarDiv">
        <Navbar />
      </div>
      <div className="doubtsWrapper">
        <div className="doubts">
          <div className="tabsDiv">
            <div className="AllLogin">
              <button className={toggleAll} onClick={handleToggleAll}>
                All Doubts
              </button>
            </div>
            <div className="PopularLogin">
              <button className={togglePopular} onClick={handleTogglePopular}>
                Popular Doubts
              </button>
            </div>
          </div>
          <div className="doubtsContainerDiv">
            
            <ScrollToBottom className={allDoubts}>
              {doubtsArr.map((e, key) => {
                return (
                  <div className="container" key={key}>
                    <div className="doubtHeader">
                      <h4 className="studentName">{e.name}</h4>
                      <button className="popularButton" onClick= {() => setPopular(`${e.id}`)}>
                        star_outline
                      </button>
                    </div>
                    <h4 className="doubtText">{e.doubt}</h4>
                  </div>
                );
              })}
            </ScrollToBottom>

            <ScrollToBottom className={popularDoubts}>
              {popularDoubtsArr
                .map(function (e, key) {
                  return (
                    <div className="container" key={key}>
                      <div className="doubtHeader">
                        <h4 className="studentName">{e.name}</h4>
                        <button className="popularButton"  onClick= {() => unsetPopular(`${e.id}`)} >star</button>
                      </div>
                      <h4 className="doubtText">{e.doubt}</h4>
                    </div>
                  );
                })}
            </ScrollToBottom>
            <div className="videoPreviewWrapper">
              <div className="videoPreview">
                <iframe
                  className="videoIframe"
                  src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  autoplay on
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
