import './App.css';
import { Component } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { LoginPage } from './pages/login/login';
import { Setup } from './pages/setup/setup.jsx'
import { Doubts } from './pages/doubts/doubts.tsx'

class App extends Component {
  constructor() {
    super();

    this.state = {
    }
  }

  render() {
    return (
      <Router>
        <Routes>
          <Route exact path='/' element={<LoginPage />} />
          <Route exact path='/login' element={<LoginPage />} />
          <Route exact path='/setup' element={<Setup />} />
          <Route exact path='/doubts' element={<Doubts />} />
        </Routes>
      </Router>
    );
  }

}

export default App;
