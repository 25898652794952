import { useState } from "react";
import "./login.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { errorToast } from "../../config/toast-config";
import { Config } from "../../config/config";

export const LoginPage = (props) => {
  const navigate = useNavigate();
  const [centerDiv, setCenterDiv] = useState("loginBoxWrapper");
  const [resetPass, setResetPass] = useState("invisible");
  const [loginBox, setLoginBox] = useState("loginBox");
  const [showMessage, setShowMessage] = useState("invisible");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [spinner, setSpinner] = useState("spinnerWrapper");

  useState(() => {
    axios
      .get(`${Config.scheme}://${Config.host}/v1/user/self`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        navigate("/content");
      })
      .catch((error) => {
        setSpinner("invisible");
      });
  }, []);

  function showReset() {
    setLoginBox("invisible");
    setResetPass("resetPass");
    setCenterDiv("resetBoxWrapper");
  }

  function handleReset() {
    setShowMessage("instructionsDiv");
    setTimeout(function () {
      window.location = window.location;
    }, 5000);
  }

  function handleBack() {
    setLoginBox("loginBox");
    setResetPass("invisible");
    setCenterDiv("loginBoxWrapper");
  }

  function loginHandler() {
    setSpinner("spinnerWrapper");
    axios
      .post(`${Config.scheme}://${Config.host}/v1/auth/token`, {
        username: email,
        password: password,
      })
      .then((res) => {
        if (res.status === 200) {
          const accessToken = res.data.data.access_token;
          localStorage.setItem("token", accessToken);
          navigate("/content");
        } else {
          console.log(res.data);
        }
      })
      .catch((error) => {
        setSpinner("invisibleWrapper");
        toast.error(error.response.data.message, errorToast);
      });
  }

  return (
    <>
      <ToastContainer
        position="bottom-right"
        theme="dark"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
      <div className={spinner}>
        <div className="spinner" />
      </div>
      <div className="loginMain">
        <div className={centerDiv}>
          <div className="headerDiv">
            <h1 className="header">YT Doubts</h1>
          </div>
          <div className={loginBox}>
            <div className="inputWrapper">
              <div className="emailInput">
                <input
                  className="inputBox"
                  type="email"
                  placeholder="Email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                ></input>
              </div>
              <div className="passwordInput">
                <input
                  className="inputBox"
                  type="password"
                  placeholder="Password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                ></input>
              </div>
              {/* <div className="forgotPass">
                                <button className="forgotButton" onClick={showReset}>Forgot Password?</button>
                            </div> */}
              <div className="loginButtonWrapper">
                <button
                  className="purpleButtonLogin"
                  onClick={(e) => {
                    loginHandler();
                  }}
                >
                  Login
                </button>
              </div>
            </div>
          </div>
          {/* <div className={resetPass}>
                        <div className="backButtonDivWrapper">
                            <div className="backButtonDiv">
                                <button className="backButton" onClick={handleBack}>arrow_back</button>
                            </div>
                        </div>
                        <div className="resetPassEmail">
                            <div className="resetPassEmailWrapper">
                                <div className="labelDiv">
                                    <label className="resetPassHeader">PLEASE ENTER YOUR REGISTERED EMAIL ID</label>
                                </div>
                                <div className="resetInputDiv">
                                    <input className="resetPassEmailBox" placeholder="Email Id"></input>
                                </div>
                            </div>
                        </div>
                        <div className="resetButtonWrapper">
                            <button className="resetButton" onClick={handleReset}>Reset</button>
                        </div>
                        <div className={showMessage}>
                            <p className="instructions">Please check your email for further instructions.</p>
                        </div>
                    </div> */}
        </div>
      </div>
    </>
  );
};
